import React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import styled from "styled-components"
import Hero from "../../content/assets/hero2.jpg"
import favicon from "../../content/assets/favicon.ico"

import { rhythm, scale } from "../utils/typography"
import Button from "./button"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <div
          style={{
            backgroundImage: `url(${Hero})`,
            width: `100vw`,
            backgroundRepeat: `no-repeat`,
            backgroundAttachment: `fixed`,
            backgroundPosition: `center`,
            height: `600px`,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "end",
            }}
          >
            <Button>
              <Link to={`/about/`}>About</Link>
            </Button>
          </div>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <h1
              style={{
                ...scale(1.5),
                marginBottom: rhythm(1.5),
                marginTop: 0,
                marginRight: "auto",
                marginLeft: "20px",
                color: "white",
                letterSpacing: "-4px",
                textAlign: "center",
              }}
            >
              <Link
                style={{
                  boxShadow: `none`,
                  textDecoration: `none`,
                  color: `inherit`,
                }}
                to={`/`}
              >
                {title}
              </Link>
            </h1>
            <h2
              style={{
                textAlign: "center",
                paddingLeft: "33px",
              }}
            >
              Ramblings on Minimalism
            </h2>
          </div>
        </div>
      )
    } else {
      header = (
        <h3
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to="/"
          >
            {title}
          </Link>
        </h3>
      )
    }
    return (
      <Wrapper>
        <Helmet>
          <link rel="icon" href={favicon} />
        </Helmet>
        {location.pathname === rootPath ? <header>{header}</header> : ""}
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          }}
        >
          {location.pathname === rootPath ? "" : <header>{header}</header>}
          {location.pathname === rootPath ? <h1>Lastest posts</h1> : ""}
          <main>{children}</main>
        </div>
        <Footer>
          © {new Date().getFullYear()},{` `}
          Minimalist Edge
        </Footer>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: #fcfcfc;

  a {
    font-weight: bold;
    text-decoration: none;
    color: inherit;
  }
`

const Footer = styled.footer`
  text-align: center;
  padding: 30px;
`

export default Layout
