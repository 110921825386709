import React from "react"
import styled from "styled-components"

const Button = props => (
  <ButtonWrapper props={props}>{props.children}</ButtonWrapper>
)

const ButtonWrapper = styled.button`
  display: block;
  border: none;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 2px 15px;
  border-radius: 16px;
  margin: 20px;
  margin-top: 15px;
  margin-right: 40px;
  opacity: 0.5;
  font-weight: 900;

  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.25);
  }

  a:link {
    text-decoration: none;
  }
`

export default Button
